<template>
  <div class="the-navbar__user-meta flex items-center ml-6" v-if="user.id">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ user.person.firstname }} {{ user.person.lastname }}</p>
      <small>{{ whoIs(user.type) }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img key="onlineImg" :src="showAvatar()" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block aa-vie-navbar-profil-img" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 11rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2" @click="gotToProfil">Mon profil</span>
          </li>

          <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"> 
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2" @click="gotToSettings">Paramètres</span>
          </li>-->

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="onLogout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Déconnexion</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
    }
  },
  mounted() {
    if(this.user) {

    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("general", ["allUserTypes"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    whoIs(type) {
      let types = this.allUserTypes
      console.log("types", type, types)
      return types[type]
    }, 
    showAvatar() {
      if(this.user.person.avatar) {
        return `${this.$config.serverURL}/downloads/${this.user.person.avatar}`
      } else {
        return require("@/assets/images/avatar/man.png")
      }
    },
    async onLogout() {
      try {
        await this.logout();
        this.$router.push({ name: "Login" });
      } catch (err) {
        console.log("Logout Error", err);
      }
    },
    gotToProfil() {
      this.$router.push({ name: "Profil" });
    },
    gotToSettings() {
      this.$router.push({ name: "Settings" });
    },
  },
  watch: {
  },
}
</script>
<style lang="scss">
  .aa-vie-navbar-profil-img {
    height: 40px!important;
    width: 40px!important;
  }
</style>
