/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Accueil",
    slug: "Accueil",
    icon: "HomeIcon",
    isDisabledBrokerSimple: false,
  },
  {
		url: "/offer",
		name: "Offres",
		slug: "Offres",
    icon: "SunIcon",
    isDisabledBrokerSimple: false,
  },
  {
    url: "/projet-contrat",
    name: "Projets & Contrats",
    slug: "Projets & Contrats",
    icon: "UsersIcon",
    isDisabledBrokerSimple: false,
  },
  {
    url: "/slips",
    name: "Bordereaux",
    slug: "Bordereaux",
    icon: "LayersIcon",
    isDisabledBrokerSimple: false,
  },
  {
		url: "/stats-team",
		name: "Statistique Equipe",
		slug: "Statistique Equipe",
    icon: "BarChart2Icon",
    isDisabledBrokerSimple: true,
  },
]
